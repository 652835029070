import { useRobotIdentity } from '@sbrc/services/feathers-client/robot-identity';

import AssemblyToolsPage from './assembly-tools';
import HomePage from './home';

export default function MainPage() {
  const robotIdentity = useRobotIdentity();
  const isAssembly = robotIdentity?.robotOperationMode === 'assembly';

  if (isAssembly) {
    return <AssemblyToolsPage />;
  }

  return <HomePage />;
}
